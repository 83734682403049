<template>
  <div>
    <el-form
      :model="formshareholder"
      ref="formshareholder"
      :hide-required-asterisk="true"
    >
      <div class="row">
        <div class="col-6">
          <el-form-item
            :label="'Office'"
            prop="office"
            :rules="[{ required: true, message: 'Office is required' }]"
          >
            <el-select
              class="w-100"
              v-model="formshareholder.office"
              placeholder="Select office"
              @change="getUsersOffice"
            >
              <el-option
                v-for="item in offices"
                :key="item.id"
                :label="item.office_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="col-6">
          <el-form-item :label="'User / Preparer:'" prop="preparer">
            <el-select
              class="w-100"
              v-model="formshareholder.preparer"
              placeholder="Select User"
            >
              <el-option
                v-for="item in users"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="col-12">
          <hr />
          <h5>Taxpayer:</h5>
          <br />
        </div>

        <div class="col-3">
          <el-form-item
            :label="$t('shareholder.firstname')"
            prop="name"
            :rules="[{ required: true, message: 'The field is required' }]"
          >
            <el-input
              type="text"
              v-model="formshareholder.name"
              placeholder="Name"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-3">
          <el-form-item
            :label="$t('shareholder.lastname')"
            prop="lastname"
            :rules="[{ required: true, message: 'The field is required' }]"
          >
            <el-input
              type="text"
              v-model="formshareholder.lastname"
              placeholder="Last Name"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-3">
          <el-form-item
            :label="$t('shareholder.socialsecurity')"
            prop="securitysocial"
            :rules="[
              { required: true, message: 'Security social is required' },
            ]"
          >
            <el-input
              type="text"
              @change="searchShareholder"
              v-model="formshareholder.securitysocial"
              placeholder="e.g 554-55-998"
              v-mask="'NNN-NN-NNNN'"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-3">
          <el-form-item :label="$t('shareholder.birthday')" prop="birthday">
            <el-date-picker
              type="date"
              v-model="formshareholder.birthday"
              value-format="yyyy-MM-dd"
              format="MM/dd/yyyy"
              placeholder="Pick a day"
              class="w-100"
            ></el-date-picker>
          </el-form-item>
        </div>

        <div class="col-6">
          <el-form-item :label="$t('shareholder.email')" prop="email">
            <el-input
              type="email"
              v-model="formshareholder.email"
              placeholder="example@mail.com"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-3">
          <el-form-item :label="$t('shareholder.phone')" prop="phone">
            <el-input
              type="text"
              v-model="formshareholder.phone"
              placeholder="e.g (414) 639-1121"
              v-mask="'(###) ###-####'"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-3">
          <el-form-item :label="'Language'" prop="language">
            <v-select
              class="style-chooser"
              placeholder="Select language"
              :options="['English', 'Spanish']"
              v-model="formshareholder.language"
            ></v-select>
          </el-form-item>
        </div>

        <div class="col-12">
          <el-form-item :label="$t('shareholder.address')" prop="address">
            <el-input
              type="text"
              v-model="formshareholder.address"
              placeholder="Address"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-6">
          <el-form-item :label="$t('shareholder.city')" prop="city">
            <el-input
              type="text"
              v-model="formshareholder.city"
              placeholder="e.g Livingston"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-3">
          <el-form-item :label="'State'" prop="state">
            <v-select
              class="style-chooser"
              placeholder="Select state"
              :options="states"
              label="name"
              :reduce="(state) => state.id"
              v-model="formshareholder.state"
            ></v-select>
          </el-form-item>
        </div>

        <div class="col-3">
          <el-form-item
            :label="$t('shareholder.zip')"
            prop="zip"
            :rules="[{ pattern: /^[0-9]{5}$/, message: 'Format zip invalid' }]"
          >
            <el-input
              type="text"
              v-model="formshareholder.zip"
              placeholder="e.g 12345"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-12">
          <div class="row">
            <div class="col-2">
              <label for="filing" class="mt-2">
                Filing Status:
              </label>
            </div>

            <div class="col-2">
              <el-form-item prop="filing">
                <el-radio
                  label="Single"
                  v-model="formshareholder.filingstatus"
                ></el-radio>
              </el-form-item>
            </div>

            <div class="col-2">
              <el-form-item prop="filing">
                <el-radio
                  label="Married"
                  v-model="formshareholder.filingstatus"
                ></el-radio>
              </el-form-item>
            </div>
          </div>
        </div>

        <div class="col-12" v-if="formshareholder.filingstatus === 'Married'">
          <hr class="mt-0" />
          <h5>Spouse:</h5>
          <br />
        </div>
        <div class="col-3" v-if="formshareholder.filingstatus === 'Married'">
          <el-form-item
            :label="$t('shareholder.firstname')"
            prop="spouse_name"
            :rules="[
              {
                required: formshareholder.filingstatus === 'Married',
                message: 'The field is required',
              },
            ]"
          >
            <el-input
              type="text"
              v-model="formshareholder.spouse_name"
              placeholder="Name"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-3" v-if="formshareholder.filingstatus === 'Married'">
          <el-form-item
            :label="$t('shareholder.lastname')"
            prop="spouse_lastname"
            :rules="[
              {
                required: formshareholder.filingstatus === 'Married',
                message: 'The field is required',
              },
            ]"
          >
            <el-input
              type="text"
              v-model="formshareholder.spouse_lastname"
              placeholder="Last Name"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-3" v-if="formshareholder.filingstatus === 'Married'">
          <el-form-item
            :label="$t('shareholder.socialsecurity')"
            prop="spouse_securitysocial"
            :rules="[
              {
                required: formshareholder.filingstatus === 'Married',
                message: 'Security social is required',
              },
            ]"
          >
            <el-input
              type="text"
              v-model="formshareholder.spouse_securitysocial"
              placeholder="e.g 554-55-998"
              v-mask="'NNN-NN-NNNN'"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-3" v-if="formshareholder.filingstatus === 'Married'">
          <el-form-item
            :label="$t('shareholder.birthday')"
            prop="spouse_birthday"
          >
            <el-date-picker
              type="date"
              v-model="formshareholder.spouse_birthday"
              value-format="yyyy-MM-dd"
              format="MM/dd/yyyy"
              placeholder="Pick a day"
              class="w-100"
            ></el-date-picker>
          </el-form-item>
        </div>

        <div class="col-6" v-if="formshareholder.filingstatus === 'Married'">
          <el-form-item :label="$t('shareholder.email')" prop="spouse_email">
            <el-input
              type="email"
              v-model="formshareholder.spouse_email"
              placeholder="example@mail.com"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-3" v-if="formshareholder.filingstatus === 'Married'">
          <el-form-item :label="$t('shareholder.phone')" prop="spouse_phone">
            <el-input
              type="text"
              v-model="formshareholder.spouse_phone"
              placeholder="e.g (414) 639-1121"
              v-mask="'(###) ###-####'"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-3" v-if="formshareholder.filingstatus === 'Married'">
          <el-form-item :label="'Language'" prop="spouse_language">
            <v-select
              class="style-chooser"
              placeholder="Select language"
              :options="['English', 'Spanish']"
              v-model="formshareholder.spouse_language"
            ></v-select>
          </el-form-item>
        </div>

        <div class="col-12">
          <hr class="mt-0" />
          <br />
        </div>
        <div class="col-6">
          <el-form-item :label="'Active Date'" prop="activedate">
            <el-date-picker
              type="date"
              v-model="formshareholder.activedate"
              value-format="yyyy-MM-dd"
              format="MM/dd/yyyy"
              placeholder="Pick a date"
              class="w-100"
            ></el-date-picker>
          </el-form-item>
        </div>

        <div class="col-6">
          <el-form-item :label="'Inactive Date'" prop="inactivedate">
            <el-date-picker
              type="date"
              v-model="formshareholder.inactivedate"
              value-format="yyyy-MM-dd"
              format="MM/dd/yyyy"
              placeholder="Pick a date"
              class="w-100"
            ></el-date-picker>
          </el-form-item>
        </div>

        <div class="col-12 mb-4 mt-2">
          <el-button
            type="primary"
            class="float-left"
            @click="
              !data
                ? submitForm('formshareholder')
                : updateForm('formshareholder')
            "
            >Save Client</el-button
          >
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import location from "@/services/api/location";
import shareholder from "@/services/api/shareholder";
import title from "@/services/api/title";
import office from "@/services/api/office";
import officeUser from "@/services/api/officeUser";
import user from "@/services/api/user";

export default {
  props: ["data", "ss"],
  data() {
    return {
      offices: [],
      users: [],
      states: [],
      titles: [],
      formshareholder: {
        new: true,
        office: null,
        preparer: null,
        name: null,
        lastname: null,
        title: null,
        securitysocial: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        email: null,
        phone: null,
        birthday: null,
        language: null,
        filingstatus: "Single",
        spouse_name: null,
        spouse_lastname: null,
        spouse_securitysocial: null,
        spouse_birthday: null,
        spouse_email: null,
        spouse_phone: null,
        spouse_language: null,
        activedate: null,
        inactivedate: null,
      },
    };
  },
  mounted() {
    switch (this.$store.getters.discriminator) {
      case "administrator":
        office.get().then((response) => {
          this.offices = response;
        });
        break;
      case "office":
      case "employee":
        officeUser.getOffice(this.$store.getters.id).then((response) => {
          this.offices = response.map((item) => {
            return {
              id: item.office.id,
              office_name: item.office.office_name,
            };
          });
        });
        break;
    }

    location.state().then((response) => {
      this.states = response;
    });

    title.get().then((response) => {
      this.titles = response;
    });

    if (this.data) {
      this.formshareholder.new = false;
      this.formshareholder.id = this.data.id;
      this.formshareholder.name = this.data.name;
      this.formshareholder.lastname = this.data.lastName;
      this.formshareholder.office = this.data.officeId;
      this.getUsersOffice();
      this.formshareholder.preparer = this.data.preparer;
      this.formshareholder.title = this.data.titleId;
      this.formshareholder.securitysocial = this.data.securitySocial;
      this.formshareholder.address = this.data.address;
      this.formshareholder.city =
        this.data.city != null ? this.data.city.name : "";
      this.formshareholder.state =
        this.data.city != null ? this.data.city.stateId : "";
      this.formshareholder.zip = this.data.zip;
      this.formshareholder.birthday = this.data.birthDay;
      this.formshareholder.email = this.data.email;
      this.formshareholder.phone = this.data.phone;
      this.formshareholder.language = this.data.languaje;
      this.formshareholder.activedate = this.data.activeDate;
      this.formshareholder.inactivedate = this.data.inactiveDate;
      this.formshareholder.filingstatus = this.data.filingStatus;
      if (this.data.spouse !== null && this.data.filingStatus === "Married") {
        this.formshareholder.spouse_name = this.data.spouse.name;
        this.formshareholder.spouse_lastname = this.data.spouse.lastName;
        this.formshareholder.spouse_securitysocial = this.data.spouse.securitySocial;
        this.formshareholder.spouse_birthday = this.data.spouse.birthDay;
        this.formshareholder.spouse_email = this.data.spouse.email;
        this.formshareholder.spouse_phone = this.data.spouse.phone;
        this.formshareholder.spouse_language = this.data.spouse.languaje;
      }
    }
  },
  methods: {
    getUsersOffice() {
      user.getUsersByOffice(this.formshareholder.office).then((response) => {
        this.users = response;
      });
    },
    searchShareholder() {
      if (this.formshareholder.securitysocial != null) {
        shareholder
          .getBySecuritysocial(this.formshareholder.securitysocial)
          .then((response) => {
            if (response.id != null) {
              this.formshareholder.id = response.id;
              this.formshareholder.name = response.name;
              this.formshareholder.lastname = response.lastName;
              this.formshareholder.office = response.officeId;
              this.getUsersOffice();
              this.formshareholder.preparer = response.preparer;
              this.formshareholder.title = response.titleId;
              this.formshareholder.securitysocial = response.securitySocial;
              this.formshareholder.address = response.address;
              this.formshareholder.city =
                response.city != null ? response.city.name : "";
              this.formshareholder.state =
                response.city != null ? response.city.stateId : "";
              this.formshareholder.zip = response.zip;
              this.formshareholder.birthday = response.birthDay;
              this.formshareholder.email = response.email;
              this.formshareholder.phone = response.phone;
              this.formshareholder.language = response.languaje;
              this.formshareholder.activedate = response.activeDate;
              this.formshareholder.inactivedate = response.inactiveDate;
              this.formshareholder.filingstatus = response.filingStatus;
              if (
                response.spouse !== null &&
                response.filingStatus === "Married"
              ) {
                this.formshareholder.spouse_name = response.spouse.name;
                this.formshareholder.spouse_lastname = response.spouse.lastName;
                this.formshareholder.spouse_securitysocial =
                  response.spouse.securitySocial;
                this.formshareholder.spouse_birthday = response.spouse.birthDay;
                this.formshareholder.spouse_email = response.spouse.email;
                this.formshareholder.spouse_phone = response.spouse.phone;
                this.formshareholder.spouse_language = response.spouse.languaje;
              }
            } else {
              this.formshareholder.new = true;
            }
          });
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          shareholder
            .create(this.formshareholder)
            .then((response) => {
              this.$emit("refresh", { data: response, update: false });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error",
              });
            });
        } else {
          return false;
        }
      });
    },
    updateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          shareholder
            .update(this.formshareholder)
            .then((response) => {
              this.$emit("refresh", { data: response, update: true });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error",
              });
            });
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    ss: {
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        if (val) {
          switch (val.value.length) {
            case 9:
              this.formshareholder.securitysocial = val.value.replace(
                /^([0-9]{3})([0-9]{2})([0-9]{4})$/,
                "$1-$2-$3"
              );
              this.searchShareholder();
              break;
            case 11:
              this.formshareholder.securitysocial = val.value;
              this.searchShareholder();
              break;
          }
        } else {
          this.formshareholder = {
            new: true,
            office: null,
            preparer: null,
            name: null,
            lastname: null,
            title: null,
            securitysocial: null,
            address: null,
            city: null,
            state: null,
            zip: null,
            email: null,
            phone: null,
            birthday: null,
            language: null,
            filingstatus: "Single",
            spouse_name: null,
            spouse_lastname: null,
            spouse_securitysocial: null,
            spouse_birthday: null,
            spouse_email: null,
            spouse_phone: null,
            spouse_language: null,
            activedate: null,
            inactivedate: null,
          };
        }
      },
    },
  },
};
</script>

<style lang="scss"></style>
